import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useCookie } from '@use-hook/use-cookie';

import { navigate, useLocation } from '@reach/router';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import { useCtaRegistration } from '~hooks/useAuth';
import BFExitIntentPopup from '~components/black-friday/BFExitIntentPopup';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import useBlackFridayTargetAudience from '~components/black-friday/useTargetAudience';

import ExitIntentPopup from './ExitIntentPopup';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const ExitIntentPopupContainer = () => {
  const query = graphql`
    {
      prismicExitIntentPopup {
        data {
          body {
            ... on PrismicExitIntentPopupDataBodyExitIntentVariant {
              slice_type
              primary {
                title
                subtitle
                image {
                  url
                  raw
                }
                button_title
                button_background_color
                guide_link {
                  url
                }
                page_slug
                provide_material_immediately
                tags
              }
            }
          }
        }
      }
    }
  `;

  const location = useLocation();
  const { pathname } = location;

  const { prismicExitIntentPopup } = useStaticQuery(query);
  const { data } = prismicExitIntentPopup;

  const { body } = data;

  const formattedExitIntents = body.length && body.map(item => ({ ...item.primary }));

  const defaultExitIntentDate =
    formattedExitIntents && formattedExitIntents.find(intentItem => intentItem.page_slug === '*');

  const pageExitIntentDate =
    formattedExitIntents &&
    formattedExitIntents.find(intentItem => intentItem.page_slug === pathname);

  const pageData = pageExitIntentDate || defaultExitIntentDate;

  const href = pageData && pageData.guide_link?.url;

  const isBlackFridayTargetAudience = useBlackFridayTargetAudience();
  const isBlackFridayExitIntent = IS_BLACK_FRIDAY_OFFER_ENABLED && isBlackFridayTargetAudience;

  const exitIntentId = isBlackFridayExitIntent ? 'black-friday' : pageData?.page_slug;

  const { session } = useUserContext();
  const { isLoggedIn, isUserStateLoading } = session;

  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, data: ctaRegisterData, error } = regState;

  const [isOpen, setIsOpen] = useState(false);
  const [isExitIntentPopupHidden, setIsExitIntentPopupHidden] = useCookie(
    `hide-exit-intent-popup-${exitIntentId}`,
    false
  );

  const handleOpenPopup = () => {
    if (!isLoggedIn && !isExitIntentPopupHidden) {
      setIsOpen(true);
    }
  };

  const checkMousePosition = event => {
    if (event.clientY <= 2) {
      handleOpenPopup();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsExitIntentPopupHidden(true, { expires: 1 });
  };

  useEffect(() => {
    window.addEventListener('mouseout', checkMousePosition);
    window.addEventListener('blur', handleOpenPopup);

    if (ctaRegisterData && href && pageData?.provide_material_immediately) {
      // NOTE: below is a hack because simple `window.open(href, '_blank')` doesn't work in Mobile Safari.
      // Main reason is probably the call to `window.open` inside an async operation, see https://stackoverflow.com/a/39387533
      const pdfGuideWindow = window.open(href, 'PDFGuideWindow');
      // can be empty if we couldn't open the window, for example, on Safari mobile.
      if (!pdfGuideWindow) {
        // In browsers who straight off reject `window.open` then just navigate to that `href`.
        // Instead of opening in a separate tab the `href` will open in the same one.
        navigate(href);
      }
      handleClose();
    }

    return () => {
      window.removeEventListener('mouseout', checkMousePosition);
      window.removeEventListener('blur', handleOpenPopup);
    };
  }, [ctaRegisterData, error, isError, isLoading]);

  if (isBlackFridayExitIntent) {
    return <BFExitIntentPopup isOpen={isOpen} handleClose={handleClose} />;
  }

  if (
    !pageData ||
    isUserStateLoading ||
    (pageData.provide_material_immediately && isLoggedIn) ||
    isExitIntentPopupHidden
  ) {
    return null;
  }

  return (
    <ExitIntentPopup
      data={pageData}
      state={regState}
      registerCta={registerCta}
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default ExitIntentPopupContainer;
